import getIsMobile from './getIsMobile'
import log from './logging'
import { markdownToHtml } from './markdown'

const removeMarkdown = (markdown: string) => {
  return markdown.replace(/[#*_~`]/g, '')
}

const copyListener = (markdown: string) => (e: ClipboardEvent) => {
  if (!e.clipboardData) return
  const html = markdownToHtml(markdown)
  e.clipboardData.setData('text/html', html.split('\n').join(''))
  e.clipboardData.setData('text/plain', removeMarkdown(markdown))
  e.clipboardData.setData('text/markdown', markdown)
  e.clipboardData.setData('text', removeMarkdown(markdown))
  e.preventDefault()
}

export const copyMarkdownFormatted = (markdown: string) => {
  try {
    const listener = copyListener(markdown)

    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)

    if (getIsMobile()) {
      navigator.clipboard.writeText(removeMarkdown(markdown))
    }
  } catch (err) {
    log.error(err)
    navigator.clipboard.writeText(removeMarkdown(markdown))
  }
}
