import DownloadIcon from '@mui/icons-material/DownloadRounded'
import CopyIcon from '@mui/icons-material/FileCopyRounded'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import SendIcon from '@mui/icons-material/SendRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { getContainerHTML, markdownToHtml } from '../../util/markdown'
import EmailNoteDialog from '../EmailNoteDialog'
import ExportDocxButton from '../ExportDocxButton'
import { copyMarkdownFormatted } from '../../util/copy'
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded'
import ExportEHROverlay from './ExportEHROverlay'
import checkToken from '../../util/auth/checkToken'
import { ehrRequest } from '../../util/api/ehr-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import markdownToBlocks from '../markdown/parser/markdownToBlocks'
import blocksToHTML from '../markdown/blocksToHtml'

export type ExportSessionOptionsProps = {
  loading?: boolean
  note: string
  summary?: string
  patientLabel?: string
  layout?: RLayout
  startedAtString?: string
  endedAtString?: string
}

const ExportSessionOptions = ({
  loading,
  note,
  summary,
  patientLabel,
  layout,
  startedAtString,
  endedAtString,
}: ExportSessionOptionsProps) => {
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [ehrOpen, setEhrOpen] = useState(false)
  const [canEhrExport, setCanEhrExport] = useState(false)

  const handleToggleEhr = () => {
    setEhrOpen((prev) => !prev)
  }

  const handleCopy = () => {
    copyMarkdownFormatted(note || '')

    toast.success('Copied to clipboard')
  }

  const handleEmailAll = () => {
    setEmailDialogOpen((prev) => !prev)
  }

  const handlePrint = () => {
    const blocks = markdownToBlocks(note || '', false)
    const source = blocksToHTML(blocks)

    const html = getContainerHTML(source)

    const printWindow = window.open('', '_blank')
    printWindow?.document.open()
    printWindow?.document.write(html)
  }

  const filename = useMemo(() => {
    const title = summary
    if (title && patientLabel) {
      return `${title}-${patientLabel}`
    }
    if (title) {
      return `${title}`
    }
    if (patientLabel) {
      return `${patientLabel}`
    }
    return 'clinical-notes'
  }, [summary, patientLabel])

  const populateCanEhrExport = useCallback(async () => {
    try {
      await checkToken()

      const { total } = await ehrRequest.integrations.patients.list({
        token: '',
        limit: 1,
      })

      if (total > 0) {
        setCanEhrExport(true)
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateCanEhrExport()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateCanEhrExport])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            startIcon={<CopyIcon />}
            fullWidth
            onClick={handleCopy}
            disabled={loading}
            sx={{ p: 2 }}
          >
            Copy All
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            startIcon={<SendIcon />}
            fullWidth
            onClick={handleEmailAll}
            disabled={loading || emailLoading}
            sx={{ p: 2 }}
          >
            Email All
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            startIcon={<DownloadIcon />}
            fullWidth
            onClick={handlePrint}
            disabled={loading}
            sx={{ p: 2 }}
          >
            Print / PDF Export
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ExportDocxButton
            fullWidth
            html={markdownToHtml(note || '')}
            filename={filename}
            startIcon={<MicrosoftIcon />}
            disabled={loading}
            sx={{ p: 2 }}
          />
        </Grid>
        {canEhrExport && (
          <Grid item xs={12}>
            <Button
              startIcon={<ExtensionRoundedIcon />}
              fullWidth
              onClick={handleToggleEhr}
              disabled={loading}
              sx={{ p: 2 }}
            >
              Export to EHR
            </Button>
          </Grid>
        )}
      </Grid>
      <EmailNoteDialog
        text={note || ''}
        open={emailDialogOpen}
        patientLabel={patientLabel || undefined}
        onClose={() => setEmailDialogOpen(false)}
        onSetLoading={setEmailLoading}
        startedAtString={startedAtString || new Date().toLocaleDateString()}
        endedAtString={endedAtString || new Date().toLocaleDateString()}
      />
      <ExportEHROverlay
        open={ehrOpen}
        onClose={handleToggleEhr}
        onExport={handleToggleEhr}
        note={note}
        patientLabel={patientLabel}
        startedAtString={startedAtString}
        endedAtString={endedAtString}
        layout={layout}
      />
    </>
  )
}

export default ExportSessionOptions
