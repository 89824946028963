import { RIntegrationPatient } from '@counsel-project/counsel-ehr-api'
import { RPatient } from '@counsel-project/counsel-transcribe-api'
import Autocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { ehrRequest } from '../util/api/ehr-api'
import { transcribeRequest } from '../util/api/transcribe-api'
import checkToken from '../util/auth/checkToken'
import handleError from '../util/handleError'

export const ehrs = ['kipu']

const filter = createFilterOptions<RPatient>()

export type PatientSelectorProps = {
  id?: string
  placeholder?: string
  hiddenOptions?: string[]
  value: RPatient | string | null
  onChange: (value: RPatient | null) => void
  onChangeInputValue?: (value: string) => void
  onChangeShownOptions?: (options: RPatient[]) => void
} & Omit<
  AutocompleteProps<RPatient, false, false, true>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

export const capitalizeInput = (input: string) => {
  if (!input) return ''
  const words = input.split(' ')
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords.join(' ')
}

const PatientSelectorBase = (
  {
    id,
    placeholder = 'Search or Create New...',
    hiddenOptions,
    value,
    onChange,
    onChangeInputValue,
    onChangeShownOptions,
    disabled,
    ...props
  }: PatientSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [shownOptions, setShownOptions] = useState<RPatient[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const populateOptions = useCallback(
    async (search: string) => {
      try {
        await checkToken()

        setLoading(true)

        const [ehrData, data] = await Promise.all([
          ehrRequest.integrations.patients.list({
            token: '',
            search: !search
              ? undefined
              : {
                  or: [{ name: search, $fuzzy: true }],
                },
            limit: 20,
            sort: 'name',
            direction: 'asc',
          }),
          transcribeRequest.patients.list.all({
            token: '',
            search: !search
              ? undefined
              : {
                  or: [{ label: search, $fuzzy: true }],
                },
            limit: 20,
            sort: 'label',
            direction: 'asc',
          }),
        ])

        const ehrMapped = ehrData.results.map((patient: RIntegrationPatient) => ({
          _id: '',
          label: patient.name,
          userId: patient.integrationType,
          email: '',
          createdAt: patient._id,
          updatedAt: '',
        }))

        const newOptions = [
          ...ehrMapped.filter((ehrPatient) => {
            const transcribeMapping = data.results.find(
              (patient) => patient.label.trim() === ehrPatient.label.trim()
            )
            if (!transcribeMapping) {
              return true
            }
            return false
          }),
          ...data.results.map((patient) => {
            const ehrMapping = ehrData.results.find(
              (ehrPatient) => ehrPatient.name.trim() === patient.label.trim()
            )
            if (ehrMapping) {
              return {
                ...patient,
                userId: ehrMapping.userId || 'kipu',
              }
            } else {
              return patient
            }
          }),
        ]

        setShownOptions(newOptions)
        onChangeShownOptions?.(newOptions)
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [onChangeShownOptions]
  )

  useEffect(() => {
    if (value) return

    const timeout = setTimeout(() => {
      populateOptions(inputValue)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputValue, populateOptions, value])

  const handleChange = useCallback(
    async (_e: unknown, value: string | RPatient | null) => {
      if (value === null) {
        onChange(null)
        return
      }

      if (typeof value !== 'string') {
        onChange(value)
        return
      }

      try {
        setLoading(true)
        setSubmitLoading(true)

        await checkToken()

        const capitalValue = capitalizeInput(value)

        const { results } = await transcribeRequest.patients.list.all({
          token: '',
          search: {
            or: [{ label: capitalValue }, { label: value }],
          },
          limit: 1,
        })

        if (results.length === 0) {
          onChange({
            _id: '',
            label: capitalValue,
            userId: '',
            email: '',
            createdAt: '',
            updatedAt: '',
          })
          return
        }

        if (results[0]) {
          onChange(results[0])
          return
        }
      } catch (err) {
        handleError(err)
      } finally {
        setSubmitLoading(false)
        setLoading(false)
      }
    },
    [onChange]
  )

  const handleInputChange = useCallback(
    (e: React.SyntheticEvent, value: string) => {
      if (value.endsWith(',')) {
        const newValue = value.slice(0, -1)
        setInputValue(newValue)
        onChangeInputValue?.(newValue)
        return
      }

      setInputValue(value)
      onChangeInputValue?.(value)
    },
    [setInputValue, onChangeInputValue]
  )

  return (
    <Autocomplete
      {...props}
      ref={ref}
      id={id}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={shownOptions}
      loading={loading}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.label
      }}
      noOptionsText="No individuals found"
      freeSolo
      selectOnFocus
      handleHomeEndKeys
      clearOnBlur
      disabled={disabled || submitLoading}
      renderOption={(props, option) => (
        <li {...props} key={`${option.label}-${option.createdAt}`}>
          <Grid container alignItems="center">
            {ehrs.includes(option.userId) && (
              <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
                <img
                  src="/integrations/kipu.svg"
                  alt=""
                  style={{ width: 16, height: 16, objectFit: 'contain', marginRight: 4 }}
                />
              </Grid>
            )}
            <Grid item>
              <Typography variant="body1" sx={{ width: '100%' }}>
                {option._id === '' && option.userId !== 'kipu'
                  ? `Create New "${option.label}"`
                  : option.label}
              </Typography>
            </Grid>
            {/* {directoryIds.length !== 0 && (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {option.email}
                </Typography>
              </Grid>
            )} */}
          </Grid>
        </li>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue.toLowerCase() === option.label.toLowerCase()
        )

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            _id: '',
            label: capitalizeInput(inputValue),
            userId: '',
            email: '',
            createdAt: '',
            updatedAt: '',
          })
        }

        return filtered.filter((option) => {
          if (hiddenOptions?.includes(option.label)) return false
          return true
        })
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default React.forwardRef(PatientSelectorBase)
