import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LayoutOption from '../builder/LayoutOption'
import { RDirectory } from '@counsel-project/counsel-auth-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { useNavigate } from 'react-router-dom'
import SimplePagination from '../../components/forms/SimplePagination'
import getUser from '../../util/auth/getUser'

type OrganizationTemplatesProps = {
  directoryId: string
}

const OrganizationTemplates = ({ directoryId }: OrganizationTemplatesProps) => {
  const [layouts, setLayouts] = useState<RLayout[]>([])
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)

  const navigate = useNavigate()

  const populateLayouts = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const user = getUser()

      const { results, total } = await transcribeRequest.layouts.list({
        token: '',
        limit: 9,
        offset,
        sort: 'createdAt',
        direction: 'desc',
        showAll: user?.admin,
        search: {
          and: [{ directoryId }],
        },
      })

      setTotal(total)
      setLayouts(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [offset, directoryId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateLayouts()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateLayouts])

  const createNavHandler = (layout: RLayout) => () => {
    if (layout.fields) {
      navigate(`/layouts/forms/${layout._id}`)
    } else {
      navigate(`/layouts/view/${layout._id}`)
    }
  }

  return (
    <Grid container spacing={2} paddingTop={2}>
      {layouts.map((layout) => (
        <Grid item key={layout._id} xs={12} sm={6} md={4}>
          <LayoutOption selected={false} layout={layout} onClick={createNavHandler(layout)} />
        </Grid>
      ))}
      <Grid item xs={12}>
        <SimplePagination
          loading={loading}
          offset={offset}
          onClickNext={() => setOffset(offset + 9)}
          onClickPrevious={() => setOffset(offset - 9)}
          limit={9}
          total={total}
        />
      </Grid>
    </Grid>
  )
}

export default OrganizationTemplates
