import { InputField } from '@counsel-project/counsel-generation-api'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

type MultiChoiceTemplateFieldProps = {
  inputField: InputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const MultiChoiceTemplateField = ({
  inputField,
  value,
  onChange,
}: MultiChoiceTemplateFieldProps) => {
  const { id, name, type, instructions, options: inputFieldOptions } = inputField

  const [answer, setAnswer] = useState<string[]>([])
  const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    if (type === 'multiple_choice' && inputFieldOptions.length > 0) {
      setOptions(inputFieldOptions)
    }
  }, [type, inputFieldOptions])

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setAnswer(value)
    }
  }, [value])

  return (
    <Grid item xs={12} id={`field-${id}`}>
      <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
        {name}
      </Typography>
      <Autocomplete
        placeholder={instructions?.[0] || name}
        multiple
        options={options}
        value={answer}
        onChange={(_, newValue) => {
          setAnswer(newValue)
          onChange?.({ id, value: newValue })
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Grid>
  )
}

export default MultiChoiceTemplateField
