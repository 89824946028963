import { RIntegrationTemplate } from '@counsel-project/counsel-ehr-api'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'
import Switch from '@mui/material/Switch'

export type IntegrationTemplateRowProps = {
  isMobile?: boolean
  data: RIntegrationTemplate
  onClick?: (data: RIntegrationTemplate) => void
  onToggleEnabled?: (data: RIntegrationTemplate) => void
}

const IntegrationTemplateRow = ({
  isMobile,
  data,
  onClick,
  onToggleEnabled,
}: IntegrationTemplateRowProps) => {
  const { name, enabled } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {name}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell align="right">
          <Switch checked={enabled || false} onChange={() => onToggleEnabled?.(data)} />
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default IntegrationTemplateRow
