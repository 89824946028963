import {
  IntegrationTemplateSortableFields,
  ListIntegrationTemplatesByIntegrationOptions,
  ListIntegrationTemplatesByIntegrationResponse,
  RIntegrationTemplate,
} from '@counsel-project/counsel-ehr-api'
import { useCallback, useEffect, useState } from 'react'
import IntegrationTemplateTable from '../../../components/tables/IntegrationTemplateTable'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useNavigate } from 'react-router-dom'

type IntegrationTemplatesDisplayProps = {
  integrationId: string
}

const IntegrationTemplatesDisplay = ({ integrationId }: IntegrationTemplatesDisplayProps) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState<IntegrationTemplateSortableFields>('name')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [templates, setTemplates] = useState<RIntegrationTemplate[]>([])
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(false)

  const navigate = useNavigate()

  const handleNavigateTemplatePage = useCallback(
    (template: RIntegrationTemplate) => {
      navigate(`/organization/integrations/template/${template._id}`)
    },
    [navigate]
  )

  const populateTemplates = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      let searchQuery: ListIntegrationTemplatesByIntegrationOptions['search']

      if (search && showOnlyEnabled) {
        searchQuery = {
          and: [{ name: search, $fuzzy: true }, { enabled: true }],
        }
      } else if (search) {
        searchQuery = {
          and: [{ name: search, $fuzzy: true }],
        }
      } else if (showOnlyEnabled) {
        searchQuery = {
          and: [{ enabled: true }],
        }
      }

      const { results, total } = await ehrRequest.integrations.templates.listByIntegration({
        token: '',
        integrationId,
        offset: page * limit,
        limit,
        search: searchQuery,
        sort,
        direction,
      })

      setTemplates(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integrationId, page, limit, search, sort, direction, showOnlyEnabled])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateTemplates()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateTemplates])

  const handleToggleEnabled = useCallback(async (template: RIntegrationTemplate) => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await ehrRequest.integrations.templates.update({
        token: '',
        templateId: template._id,
        enabled: !template.enabled,
      })

      setTemplates((prev) => prev.map((t) => (t._id === result._id ? result : t)))
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Box>
      <FormControlLabel
        sx={{ mb: 2 }}
        control={
          <Checkbox
            checked={showOnlyEnabled}
            onChange={(e) => setShowOnlyEnabled(e.target.checked)}
          />
        }
        label="Only show enabled"
      />
      <IntegrationTemplateTable
        rows={templates}
        page={page}
        rowsPerPage={limit}
        total={total}
        loaded={!loading}
        sort={sort}
        direction={direction}
        onChangeSort={(sort, dir) => {
          setSort(sort)
          setDirection(dir)
        }}
        onChangePage={setPage}
        onChangeRowsPerPage={setLimit}
        onSubmitSearch={setSearch}
        onClickRow={handleNavigateTemplatePage}
        rowsPerPageOptions={[]}
        onToggleEnabled={handleToggleEnabled}
      />
    </Box>
  )
}

export default IntegrationTemplatesDisplay
