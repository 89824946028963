import { PermissionType, RDirectory, RLicense, RRole } from '@counsel-project/counsel-auth-api'
import AddIcon from '@mui/icons-material/AddRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ClosableDialog from '../../components/ClosableDialog'
import ConfirmDialog from '../../components/ConfirmDialog'
import SelectableTab from '../../components/SelectableTab'
import PageContainer from '../../components/layout/PageContainer'
import LicenseCard from '../../components/licensing/LicenseCard'
import RoleTable from '../../components/tables/RoleTable'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import OrganizationIntegrations from './OrganizationIntegrations'
import OrganizationSettings from './OrganizationSettings'
import OrganizationTemplates from './OrganizationTemplates'
import RoleDialog from './RoleDialog'
import EditIcon from '@mui/icons-material/EditRounded'
import IconButton from '@mui/material/IconButton'
import EditOrganizationDialog from './EditOrganizationDialog'
import OrganizationStats from './OrganizationStats'

type OrganizationPageProps = {
  directory: RDirectory
  permission: PermissionType
  priority: number
  onChangeDirectory: (directory: RDirectory) => void
}

const OrganizationPage = ({
  directory,
  permission,
  priority,
  onChangeDirectory,
}: OrganizationPageProps) => {
  const navigate = useNavigate()

  const [user] = useUser()

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [roles, setRoles] = useState<RRole[]>([])
  const [rolesTotal, setRolesTotal] = useState(0)
  const [rolesLoading, setRolesLoading] = useState(false)
  const [rolesPage, setRolesPage] = useState(0)
  const [rolesSort, setRolesSort] = useState<keyof RRole<string>>('email')
  const [rolesSortDirection, setRolesSortDirection] = useState<'asc' | 'desc'>('asc')
  const [rolesLimit] = useState(10)
  const [roleSearch, setRoleSearch] = useState('')
  const [createRoleOpen, setCreateRoleOpen] = useState(false)
  const [stagedMemberEmail, setStagedMemberEmail] = useState('')
  const [stagedMemberUseLicense, setStagedMemberUseLicense] = useState(true)
  const [addMemberLoading, setAddMemberLoading] = useState(false)
  const [memberOptionsOpen, setMemberOptionsOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState<RRole | null>(null)
  const [licensesLoading, setLicensesLoading] = useState(true)
  const [license, setLicense] = useState<RLicense | null>(null)
  const [showDeleteDirectory, setShowDeleteDirectory] = useState(false)
  const [occupiedSeats, setOccupiedSeats] = useState(0)
  const [editOpen, setEditOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const activeTab = searchParams.get('tab') ? parseInt(searchParams.get('tab') || '0') : 0

  const handleClickSeeMore = () => {
    setShowDeleteDirectory(true)
  }

  const isDirectoryOwner = directory?.ownerId === user?._id

  const roleText =
    permission === 'administrator'
      ? 'You are an Administrator here'
      : permission === 'counselor'
        ? 'You are a Counselor here'
        : permission === 'master-counselor'
          ? 'You are a Master Counselor here'
          : 'You are a Member here'

  const canDelete = permission === 'administrator'
  const canEdit = permission === 'administrator' || permission === 'master-counselor'

  const handleToggleCreateRoleOpen = () => {
    setCreateRoleOpen(!createRoleOpen)
  }

  const handleToggleConfirmDelete = () => {
    setConfirmDeleteOpen(!confirmDeleteOpen)
  }

  const handleChangeStagedMemberEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedMemberEmail(e.target.value)
  }, [])

  const handleToggleMemberOptionsOpen = () => {
    setMemberOptionsOpen((prev) => !prev)
  }

  const handleSelectRole = useCallback((role: RRole) => {
    setSelectedRole(role)
    handleToggleMemberOptionsOpen()
  }, [])

  const handleCountOccupiedSeats = useCallback(async () => {
    try {
      if (!directory) return

      await checkToken()

      const res = await authRequest.user.directories.roles.countOccupiedSeats({
        directoryId: directory._id,
        token: '',
      })

      setOccupiedSeats(res.total)
    } catch (err) {
      handleError(err)
    }
  }, [directory])

  const populateRoles = useCallback(async () => {
    try {
      if (!directory) return

      setRolesLoading(true)

      await checkToken()

      const { results, total } = await authRequest.user.directories.roles.list({
        directoryId: directory._id,
        token: '',
        offset: rolesPage * rolesLimit,
        limit: rolesLimit,
        direction: rolesSortDirection,
        search: roleSearch
          ? {
              and: [{ email: roleSearch, $fuzzy: true }],
            }
          : undefined,
      })

      setRoles(results)
      setRolesTotal(total)

      handleCountOccupiedSeats()
    } catch (err) {
      handleError(err)
    } finally {
      setRolesLoading(false)
    }
  }, [directory, rolesPage, rolesLimit, rolesSortDirection, roleSearch, handleCountOccupiedSeats])

  useEffect(() => {
    populateRoles()
  }, [populateRoles])

  const handleDeleteOrg = useCallback(async () => {
    try {
      if (!directory) return

      await checkToken()

      setDeleteLoading(true)

      await authRequest.user.directories.delete({
        directoryId: directory._id,
        token: '',
      })

      navigate('/organizations')
      toast.success('Organization deleted!')
    } catch (err) {
      handleError(err)
    } finally {
      setDeleteLoading(false)
    }
  }, [directory, navigate])

  const handleChangeRolesPage = useCallback((newPage: number) => {
    setRolesPage(newPage)
  }, [])

  const handleChangeRolesSort = useCallback(
    (newSort: keyof RRole<string>, direction: 'asc' | 'desc') => {
      setRolesSort(newSort)
      setRolesSortDirection(direction)
    },
    []
  )

  const handleAddMember = useCallback(async () => {
    try {
      if (!directory) return

      const lcaseEmail = stagedMemberEmail.toLowerCase()
      const reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!reg.test(lcaseEmail)) {
        toast.error('Oops, it looks like you did not provide a proper email address')
        return
      }

      setAddMemberLoading(true)

      await checkToken()

      await authRequest.user.directories.roles.create({
        directoryId: directory._id,
        token: '',
        email: lcaseEmail,
        permission: 'counselor',
        ignoreLicense: !stagedMemberUseLicense,
      })

      setStagedMemberEmail('')
      setCreateRoleOpen(false)
      populateRoles()
    } catch (err) {
      handleError(err)
    } finally {
      setAddMemberLoading(false)
    }
  }, [directory, stagedMemberEmail, populateRoles, stagedMemberUseLicense])

  const populateLicenses = useCallback(async () => {
    try {
      if (!directory) return

      setLicensesLoading(true)

      await checkToken()

      const { results } = await authRequest.user.directories.licenses.list({
        directoryId: directory._id,
        token: '',
        limit: 5,
      })

      // Sort by not expired first
      const sorted = results.sort((a, b) => {
        return new Date(a.expiresAt) > new Date(b.expiresAt) ? -1 : 1
      })

      setLicense(sorted[0])
      setLicensesLoading(false)
    } catch (err) {
      handleError(err)
    } finally {
      setLicensesLoading(false)
    }
  }, [directory])

  useEffect(() => {
    populateLicenses()
  }, [populateLicenses])

  const createNavHandler = useCallback(
    (path: string) => (e: React.MouseEvent) => {
      e.preventDefault()
      navigate(path)
    },
    [navigate]
  )

  const handleDeletedRole = useCallback(
    (role: RRole) => {
      toast.success('Member deleted')
      setRoles((prev) => prev.filter((r) => r._id !== role._id))
      setRolesTotal((prev) => prev - 1)
      setMemberOptionsOpen(false)
      handleCountOccupiedSeats()
    },
    [handleCountOccupiedSeats]
  )

  const handleUpdatedRole = useCallback(
    (role: RRole) => {
      toast.success('Member updated')
      setRoles((prev) => prev.map((r) => (r._id === role._id ? role : r)))
      setMemberOptionsOpen(false)
      handleCountOccupiedSeats()
    },
    [handleCountOccupiedSeats]
  )

  const handleLeaveDirectory = useCallback(async () => {
    try {
      await checkToken()

      await authRequest.user.directories.invitations.leave({
        directoryId: directory._id,
        token: '',
      })

      navigate('/')
      toast.success('You have left the organization')
    } catch (err) {
      handleError(err)
    }
  }, [directory, navigate])

  const handleDeleteDirectory = useCallback(async () => {
    try {
      await checkToken()

      await authRequest.user.directories.delete({
        directoryId: directory._id,
        token: '',
      })

      navigate('/organization')
      toast.success('Organization deleted')
    } catch (err) {
      handleError(err)
    }
  }, [directory, navigate])

  const handleToggleEditOpen = () => {
    setEditOpen((prev) => !prev)
  }

  const tabs = useMemo(() => {
    if (permission === 'administrator') {
      return ['Users', 'Settings', 'Integrations', 'Templates', 'Stats']
    }
    return ['Users', 'Settings', 'Integrations', 'Templates']
  }, [permission])

  if (!directory) {
    return (
      <PageContainer>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <CircularProgress size={48} />
          </Grid>
        </Grid>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h4" component="h1">
              {directory?.name}
            </Typography>
          </Grid>
          {permission === 'administrator' && (
            <Grid item>
              <IconButton color="primary" size="small" onClick={handleToggleEditOpen}>
                <EditIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {directory?.description && (
          <Typography variant="body1" sx={{ mt: 1 }}>
            {directory?.description}
          </Typography>
        )}
        {isDirectoryOwner ? (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }} fontStyle="italic">
            You are the owner of this Organization
          </Typography>
        ) : (
          <></>
        )}
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          {roleText}
        </Typography>
      </Paper>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        {tabs.map((tab, index) => (
          <Grid item xs={6} sm={12 / tabs.length} key={tab}>
            <SelectableTab
              active={activeTab === index}
              onClick={() =>
                setSearchParams((prev) => {
                  prev.set('tab', index.toString())
                  return prev
                })
              }
            >
              {tab}
            </SelectableTab>
          </Grid>
        ))}
      </Grid>
      {activeTab === 0 && (
        <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
          <RoleTable
            rows={roles}
            page={rolesPage}
            total={rolesTotal}
            direction={rolesSortDirection}
            loaded={!rolesLoading}
            sort={rolesSort}
            showEdit={canEdit}
            showPermission
            rowsPerPage={rolesLimit}
            onChangePage={handleChangeRolesPage}
            onChangeSort={handleChangeRolesSort}
            onSubmitSearch={setRoleSearch}
            rowsPerPageOptions={[10]}
            onClickRow={(role) => handleSelectRole(role)}
          />
          {permission !== 'counselor' && (
            <Grid container sx={{ mt: 2 }} alignItems="center" spacing={2}>
              <Grid item xs>
                {license && (
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    color="text.secondary"
                    textAlign="right"
                  >
                    {license?.maxUsers - occupiedSeats} unoccupied seats
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Button startIcon={<AddIcon />} onClick={handleToggleCreateRoleOpen}>
                  Add Member
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      )}

      {activeTab === 1 && (
        <OrganizationSettings
          disabled={permission !== 'administrator'}
          directory={directory}
          onChangeDirectory={onChangeDirectory}
        />
      )}

      {activeTab === 2 && <OrganizationIntegrations directory={directory} />}
      {activeTab === 3 && (
        <Paper sx={{ p: 2, mt: 2, minHeight: 240 }} elevation={0}>
          <Typography variant="h6" textAlign="center">
            Organization Templates
          </Typography>
          <OrganizationTemplates directoryId={directory._id} />
        </Paper>
      )}
      {activeTab === 4 && (
        <Box sx={{ mt: 2, minHeight: 240 }}>
          <Typography variant="h6" textAlign="center">
            Organization Stats
          </Typography>
          <OrganizationStats directoryId={directory._id} />
        </Box>
      )}

      {!licensesLoading && (
        <Box sx={{ mt: 2 }}>
          <LicenseCard
            license={license}
            isOrganization
            shownLimits={['dictates', 'sessions', 'documents']}
          />
        </Box>
      )}
      {!showDeleteDirectory ? (
        <Divider
          sx={{
            '& .MuiDivider-wrapper': {
              color: 'text.secondary',
            },
            '& .MuiDivider-root': {
              borderColor: 'text.secondary',
            },
            cursor: 'pointer',
            mt: 4,
          }}
          onClick={handleClickSeeMore}
        >
          See More Options
        </Divider>
      ) : (
        <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Not your organization?
          </Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Button href="/organizations/new" onClick={createNavHandler('/organizations/new')}>
              Create A New Organization
            </Button>
            {!isDirectoryOwner && (
              <Button onClick={handleLeaveDirectory} sx={{ ml: 2 }} color="error">
                Leave Organization
              </Button>
            )}
            {isDirectoryOwner && rolesTotal !== 1 && (
              <Tooltip title="You cannot delete an organization with members">
                <span>
                  <Button disabled sx={{ ml: 2 }} color="error">
                    Delete Organization
                  </Button>
                </span>
              </Tooltip>
            )}
            {isDirectoryOwner && rolesTotal === 1 && (
              <Button onClick={handleDeleteDirectory} sx={{ ml: 2 }} color="error">
                Delete Organization
              </Button>
            )}
          </Box>
        </Paper>
      )}

      <ConfirmDialog
        open={confirmDeleteOpen}
        onClose={handleToggleConfirmDelete}
        titleText="Confirm Delete"
        text="Are you sure you want to delete this organization?"
        buttonText="Delete Forever"
        onConfirm={handleDeleteOrg}
        loading={deleteLoading}
      />
      <ClosableDialog
        titleText="Add A Member"
        open={createRoleOpen}
        onClose={handleToggleCreateRoleOpen}
        fullScreenOnMobile
      >
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 1, mt: 2 }} color="text.secondary">
            Add a member to this organization by typing their email below.
          </Typography>
          <TextField
            type="email"
            autoComplete="off"
            placeholder="Type an email..."
            fullWidth
            value={stagedMemberEmail}
            onChange={handleChangeStagedMemberEmail}
          />
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={stagedMemberUseLicense}
                onChange={(e) => setStagedMemberUseLicense(e.target.checked)}
              />
            }
            label="Use organization license"
          />
          <LoadingButton
            loading={addMemberLoading}
            onClick={handleAddMember}
            disabled={
              ((license?.maxUsers || 100) <= occupiedSeats && stagedMemberUseLicense) ||
              !stagedMemberEmail
            }
          >
            Add Member
          </LoadingButton>
        </DialogActions>
      </ClosableDialog>
      {selectedRole && (
        <RoleDialog
          directoryId={directory._id}
          role={selectedRole}
          open={memberOptionsOpen}
          onClose={handleToggleMemberOptionsOpen}
          canEdit={canEdit}
          canDelete={canDelete}
          onDeleteRole={handleDeletedRole}
          onChangeRole={handleUpdatedRole}
        />
      )}
      {directory && (
        <EditOrganizationDialog
          open={editOpen}
          onClose={handleToggleEditOpen}
          directory={directory}
          onChangeDirectory={onChangeDirectory}
        />
      )}
    </PageContainer>
  )
}

export default OrganizationPage
