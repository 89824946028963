import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import useWindowSize from 'react-use/lib/useWindowSize'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'
import { onBeforeUnload, onBeforeUnloadBlocked } from '../../util/onBeforeLoad'
import theme from '../../util/theme'
import { logPurchase, useViewContent } from '../../util/tracking'
import OverlayLoader from '../builder/OverlayLoader'

const timestamp = new Date().toISOString()

const PurchaseSuccessPage = () => {
  useTitle('Clinical Notes AI - Purchase Success')
  useViewContent('Purchase Success')

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const m = searchParams.get('m')

  const { width, height } = useWindowSize()

  const maxTries = 70
  const [tries, setTries] = useState(0)
  const [loading, setLoading] = useState(true)
  const [errored, setErrored] = useState(false)

  const handleContinue = () => {
    if (m) {
      window.location.href = 'exp+counsel-mobile://app/redirects'
    } else {
      navigate('/onboarding')
    }
  }

  const populateSuccess = useCallback(async () => {
    try {
      if (tries > maxTries) {
        setErrored(true)
        return
      }

      await checkToken()

      const res = await authRequest.user.subscriptions.checkout.verify({
        token: '',
      })

      setLoading(false)
      log.info(`Successfully verified checkout: ${res.license.type}`)
    } catch (err) {
      if (
        typeof err === 'object' &&
        !!err &&
        'msg' in err &&
        err.msg === 'Subscription not found'
      ) {
        console.log(err)
      } else {
        log.error(err)
      }
      setTries((prev) => prev + 1)
    }
  }, [tries])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSuccess()
    }, 4000)
    return () => clearTimeout(timeout)
  }, [populateSuccess])

  useEffect(() => {
    const transactionId = Cookies.get('checkoutSessionId') || timestamp
    const amountTotal = parseInt(Cookies.get('checkoutAmountTotal') || '0')
    const quantity = parseInt(searchParams.get('quantity') || '0')
    // const itemId = searchParams.get('priceId') || searchParams.get('type') || 'Free'
    const itemName = searchParams.get('type') || 'Free'

    logPurchase({
      productName: itemName,
      productPrice: amountTotal,
      quantity,
      amountTotal,
      transactionId,
    })

    window.onbeforeunload = onBeforeUnloadBlocked

    return () => {
      window.onbeforeunload = onBeforeUnload
    }
  }, [searchParams])

  return (
    <OverlayLoader loaded={!loading}>
      {!loading && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={120}
          colors={[
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.success.main,
          ]}
        />
      )}
      <PageContainer>
        {!errored && loading ? (
          <Box sx={{ width: '100%', mt: 4 }}>
            <Typography
              variant="body1"
              align="center"
              fontSize={18}
              fontWeight={500}
              sx={{ mt: 1 }}
            >
              Verifying purchase...
            </Typography>
            <Typography
              variant="body1"
              align="center"
              fontSize={18}
              fontWeight={500}
              sx={{ mt: 1 }}
            >
              Please do not leave this page...
            </Typography>
          </Box>
        ) : !errored ? (
          <Box sx={{ width: '100%' }}>
            <Typography variant="h1" fontWeight={500} align="center" sx={{ mt: 4 }}>
              Congrats!
            </Typography>
            <Typography
              variant="body1"
              align="center"
              fontSize={18}
              fontWeight={500}
              sx={{ mt: 1 }}
            >
              You now have a {searchParams.get('type')} membership
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleContinue}
              sx={{ mt: 2 }}
            >
              Continue
            </Button>
          </Box>
        ) : (
          <Box sx={{ width: '100%', mt: 4 }}>
            <Typography
              variant="body1"
              align="center"
              fontSize={18}
              fontWeight={500}
              sx={{ mt: 1 }}
            >
              It looks like verification is taking a bit longer than expected. When the purchase is
              verified, it should show up upon next login.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate('/profile')}
              sx={{ mt: 2 }}
            >
              Return To Profile
            </Button>
          </Box>
        )}
      </PageContainer>
    </OverlayLoader>
  )
}

export default PurchaseSuccessPage
