import { TableInputField } from '@counsel-project/counsel-generation-api'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

type TableTemplateFieldProps = {
  inputField: TableInputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const TableTemplateField = ({ inputField, value, onChange }: TableTemplateFieldProps) => {
  const { id, name, type, headers, rows } = inputField

  const [answer, setAnswer] = useState<string[]>([])

  useEffect(() => {
    if (value) {
      setAnswer(value.map((v) => (v === '[Fill value here]' ? '' : v)))
    }
  }, [value, type])

  return (
    <Grid item xs={12} id={`field-${id}`}>
      <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
        {name}
      </Typography>
      <Grid container spacing={1}>
        {headers?.map((header, headerIndex) => (
          <Grid item xs key={`${id}-${headerIndex}`}>
            <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
              {header}
            </Typography>
          </Grid>
        ))}
        <Grid container spacing={1}>
          {rows?.map((row, rowIndex) => (
            <Grid item xs={12} container spacing={1} key={`${id}-${rowIndex}`}>
              {row.map((cell, cellIndex) => (
                <Grid item xs key={`${id}-${rowIndex}-${cellIndex}`}>
                  <TextField
                    value={answer?.[headers.length * rowIndex + cellIndex]}
                    fullWidth
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      const newAnswer = [...answer]
                      newAnswer[headers.length * rowIndex + cellIndex] = e.target.value
                      setAnswer(newAnswer)
                      onChange?.({ id, value: newAnswer })
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TableTemplateField
