import { RIntegrationPatient, RIntegrationTemplate } from '@counsel-project/counsel-ehr-api'
import { OutputField } from '@counsel-project/counsel-generation-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import { isApiError } from '@counsel-project/client-utils'
import { capitalize } from '@counsel-project/client-utils'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import OverlayLoader from '../../routes/builder/OverlayLoader'
import { usePatientNomenclature } from '../../util'
import { ehrRequest } from '../../util/api/ehr-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import ClosableDialog from '../ClosableDialog'
import IntegrationPatientSelector from '../IntegrationPatientSelector'
import IntegrationTemplateSelector from '../IntegrationTemplateSelector'
import ExportEHRPreview from './ExportEHRPreview'
import Chip from '@mui/material/Chip'

type ExportEHROverlayProps = {
  patientLabel?: string
  startedAtString?: string
  endedAtString?: string
  layout?: RLayout
  note: string
  open: boolean
  onClose: () => void
  onExport: () => void
}

const ExportEHROverlay = ({
  open,
  note,
  onClose,
  onExport,
  patientLabel,
  startedAtString,
  endedAtString,
  layout,
}: ExportEHROverlayProps) => {
  const [loading, setLoading] = useState(false)
  const [fieldsLoading, setFieldsLoading] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState<RIntegrationPatient | null>(null)
  const [selectedTemplate, setSelectedTemplate] = useState<RIntegrationTemplate | null>(null)
  const [outputFields, setOutputFields] = useState<OutputField[]>([])
  const [previewOpen, setPreviewOpen] = useState(false)
  const [templateDetails, setTemplateDetails] = useState<RIntegrationTemplate | null>(null)
  const [templateDetailsLoading, setTemplateDetailsLoading] = useState(false)

  const patientNomenclature = usePatientNomenclature()

  useEffect(() => {
    if (!selectedPatient) {
      setSelectedTemplate(null)
    }
  }, [selectedPatient])

  const handleGenerateFields = useCallback(async () => {
    try {
      if (!selectedTemplate || !selectedPatient) return

      setFieldsLoading(true)

      await checkToken()

      const { outputs } = await ehrRequest.integrations.templates.aiFill({
        token: '',
        templateId: selectedTemplate._id,
        document: note,
      })

      setOutputFields(outputs)
      setPreviewOpen(true)
    } catch (err) {
      handleError(err)
    } finally {
      setFieldsLoading(false)
    }
  }, [selectedTemplate, selectedPatient, note])

  const populatePatientField = useCallback(async () => {
    try {
      await checkToken()

      const { results, total } = await ehrRequest.integrations.patients.list({
        token: '',
        limit: 2,
        search: {
          and: [{ name: patientLabel, $fuzzy: true }],
        },
      })

      if (results[0] && total === 1) {
        setSelectedPatient(results[0])
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [patientLabel])

  const populateTemplateField = useCallback(async () => {
    try {
      if (!layout?.integrationTemplateId) return

      await checkToken()

      const { result } = await ehrRequest.integrations.templates.get({
        token: '',
        templateId: layout.integrationTemplateId,
      })

      setSelectedTemplate(result)
    } catch (err) {
      if (isApiError(err)) {
        if (err.status === 404) {
          log.warn('EHR mapped template does not exist anymore')
        }
      }
      log.warn(err)
    } finally {
      setLoading(false)
    }
  }, [layout])

  useEffect(() => {
    if (!open) return
    const timeout = setTimeout(() => {
      populateTemplateField()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateTemplateField, open])

  useEffect(() => {
    if (!open) return
    const timeout = setTimeout(() => {
      populatePatientField()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populatePatientField, open])

  const populateTemplateDetails = useCallback(async () => {
    try {
      if (!selectedTemplate) return

      setTemplateDetailsLoading(true)

      await checkToken()

      const { result } = await ehrRequest.integrations.templates.get({
        token: '',
        templateId: selectedTemplate._id,
      })

      console.log('result', result)
      setTemplateDetails(result)
    } catch (err) {
      if (!selectedTemplate) return
      setTemplateDetails({
        ...selectedTemplate,
        metadata: {
          ...selectedTemplate.metadata,
          locations: [],
        },
      })
    } finally {
      setTemplateDetailsLoading(false)
    }
  }, [selectedTemplate])

  useEffect(() => {
    if (!open) return
    if (!selectedTemplate) {
      setTemplateDetails(null)
      return
    }
    const timeout = setTimeout(() => {
      populateTemplateDetails()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateTemplateDetails, open, selectedTemplate])

  const handleExport = useCallback(async () => {
    onClose()
    setPreviewOpen(false)
  }, [onClose])

  const templateError = useMemo(() => {
    if (!templateDetails) return null
    if (!selectedPatient) return null
    if (!('locations' in templateDetails.metadata)) return null
    if (!Array.isArray(templateDetails.metadata.locations)) return null
    if (!('locationId' in selectedPatient.metadata)) return null
    if (!selectedPatient.metadata.locationId) return null
    if (!templateDetails.metadata.locations) return null
    if (templateDetails.metadata.locations.length === 0) return null
    // Check if the patient's location is in the template's locations
    return !templateDetails.metadata.locations.some(
      (l) => l.locationId === (selectedPatient.metadata.locationId as string)
    )
      ? "The template is not available in the patient's location. Export errors may occur."
      : null
  }, [templateDetails, selectedPatient])

  if (fieldsLoading) {
    return (
      <OverlayLoader>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Generating fields... This may take a few seconds.
        </Typography>
      </OverlayLoader>
    )
  }

  if (previewOpen && selectedTemplate && selectedPatient) {
    return (
      <ExportEHRPreview
        inputFields={templateDetails?.fields || []}
        outputFields={outputFields}
        onChangeOutputFields={setOutputFields}
        template={selectedTemplate}
        patient={selectedPatient}
        startedAtString={startedAtString || ''}
        endedAtString={endedAtString || ''}
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        onExport={handleExport}
      />
    )
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Export To EHR">
      <DialogContent>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Exporting the document will create a new entry in the EHR.
        </Typography>
        <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
          Select an EHR {capitalize(patientNomenclature)}
        </Typography>
        <IntegrationPatientSelector value={selectedPatient} onChange={setSelectedPatient} />
        {selectedPatient && 'locationName' in selectedPatient.metadata && (
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            Location: {selectedPatient.metadata.locationName as string}
          </Typography>
        )}
        <Typography variant="body1" fontWeight={500} sx={{ mb: 1, mt: 2 }}>
          Select a template from your EHR
        </Typography>
        <IntegrationTemplateSelector
          value={selectedTemplate}
          onChange={setSelectedTemplate}
          disabled={!selectedPatient}
          integrationId={selectedPatient?.integrationId}
        />
        {selectedTemplate &&
          'locations' in selectedTemplate.metadata &&
          Array.isArray(selectedTemplate.metadata.locations) && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              Locations: {selectedTemplate.metadata.locations.map((l) => l.locationName).join(', ')}
            </Typography>
          )}
        {templateError && (
          <Typography variant="body1" color="error" sx={{ mt: 1 }}>
            {templateError}
          </Typography>
        )}
        {selectedTemplate?.missing && (
          <Typography variant="body1" color="error" sx={{ mt: 1 }}>
            Template is not found in your EHR. Please check to make sure the template is enabled and
            available in your EHR.
          </Typography>
        )}
        {selectedPatient?.missing && (
          <Typography variant="body1" color="error" sx={{ mt: 1 }}>
            Patient is not found in your EHR. Please check to make sure the patient is enabled and
            available in your EHR.
          </Typography>
        )}
        {selectedPatient?.integrationType === 'kipu' && (
          <>
            <Typography variant="body1" color="primary" sx={{ mt: 1, mb: 1 }}>
              Kipu allows the following field types to be exported:{' '}
            </Typography>
            {[
              'text',
              'string',
              'notes',
              'radio_buttons',
              'check_box',
              'drop_down_list',
              'matrix',
            ].map((s) => (
              <Chip key={s} label={s} variant="outlined" size="small" sx={{ mr: 0.5 }} />
            ))}
            <Typography variant="body1" color="text.secondary" fontStyle="italic" sx={{ mt: 1 }}>
              If your Kipu template uses different field types, the data may not be recognized.
            </Typography>
          </>
        )}
        <Divider sx={{ mt: 2 }} />
        <LoadingButton
          fullWidth
          sx={{ mt: 2 }}
          disabled={!selectedTemplate || !selectedPatient}
          loading={loading || fieldsLoading || templateDetailsLoading}
          onClick={handleGenerateFields}
        >
          Generate Preview
        </LoadingButton>
      </DialogContent>
    </ClosableDialog>
  )
}

export default ExportEHROverlay
