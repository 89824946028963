import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import IconButton from '@mui/material/IconButton'

const facebookLink = 'https://www.facebook.com/ClinicalNotesAI/'
const instagramLink = 'https://www.instagram.com/clinicalnotesai/'
const linkedinLink = 'https://www.linkedin.com/company/clinical-notes-ai/'

const Footer = () => {
  // If webView query parameter is present, set webView
  const webView = window.location.search.includes('webView=true')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4,
      }}
    >
      {!webView && (
        <Box sx={{ mb: 2, mt: 4 }}>
          <IconButton
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
            }}
            aria-label="facebook"
            href={facebookLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
              mx: 2,
            }}
            aria-label="instagram"
            target="_blank"
            rel="noopener noreferrer"
            href={instagramLink}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
            }}
            aria-label="linkedin"
            target="_blank"
            rel="noopener noreferrer"
            href={linkedinLink}
          >
            <LinkedInIcon />
          </IconButton>
        </Box>
      )}
      <Typography variant="body2" color="text.secondary" align="center">
        {'© '}
        {new Date().getFullYear()} Clinical Notes Inc.
        <br />
        By using this site, you agree to our{' '}
        <Link
          href="https://www.clinicalnotes.ai/terms-of-service.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        See our{' '}
        <Link
          href="https://www.clinicalnotes.ai/privacy-policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        App Version 271
      </Typography>
      <Typography variant="body2" align="center">
        <Link href="/help">Contact Us</Link>
      </Typography>
    </Box>
  )
}

export default Footer
