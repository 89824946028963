import { InputField } from '@counsel-project/counsel-generation-api'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

type SingleChoiceTemplateFieldProps = {
  inputField: InputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const SingleChoiceTemplateField = ({
  inputField,
  value,
  onChange,
}: SingleChoiceTemplateFieldProps) => {
  const { id, name, type, instructions, options: inputFieldOptions } = inputField

  const [answer, setAnswer] = useState<string>('')
  const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    if (type === 'single_choice' && inputFieldOptions.length > 0) {
      setOptions(inputFieldOptions)
    }
  }, [type, inputFieldOptions])

  useEffect(() => {
    if (value[0]) {
      setAnswer(value[0])
    }
  }, [value])

  return (
    <Grid item xs={12} id={`field-${id}`}>
      <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
        {name}
      </Typography>
      <Select
        fullWidth
        placeholder={instructions?.[0] || name}
        value={answer.toString()}
        onChange={(e) => {
          const option = options.find((option) => option.toString() === e.target.value)
          if (!option) return
          setAnswer(option)
          onChange?.({ id, value: [option] })
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={`${id}-${index}`} value={option.toString()}>
            {option.toString()}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}

export default SingleChoiceTemplateField
