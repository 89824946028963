import { Recorder } from '@counsel-project/client-utils'
import {
  TranscriptionSocket,
  createSocket,
  createTranscriptString,
} from '@counsel-project/counsel-transcription-api'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { wait } from '@testing-library/user-event/dist/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import NewRecorderOverlay from '../../../components/NewRecorderOverlay'
import SelectAudioDeviceDialog, {
  DeviceOptions,
} from '../../../components/audio/SelectAudioDeviceDialog'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import { refreshSessionsCache } from '../../../util/api/transcribe-api-cached'
import { transcriptionServerUrl } from '../../../util/api/url'
import checkToken from '../../../util/auth/checkToken'
import refreshToken from '../../../util/auth/refreshToken'
import useLayouts from '../../../util/auth/useLayouts'
import handleError from '../../../util/handleError'
import log from '../../../util/logging'
import { getContainerHTML, markdownToHtml, removeMaliciousHTML } from '../../../util/markdown'
import { onBeforeUnload, onBeforeUnloadBlocked } from '../../../util/onBeforeLoad'
import { disableWakelock, enableWakelock } from '../../../util/wakelock'
import ChangeMicrophoneDialog from '../ChangeMicrophoneDialog'
import OverlayLoader from '../OverlayLoader'
import CheckAudioSupportDialog from './CheckAudioSupportDialog'
import RecoverSessionDialog from './RecoverSessionDialog'
import ResumeSessionDialog from './ResumeSessionDialog'
import { clearTranscripts, retrieveTranscripts, storeTranscripts } from './lib'
import { transcriptionRequest } from '../../../util/api/transcription-api'
import { isLanguageCode } from '@counsel-project/counsel-generation-api'
import NetworkIssuesDialog from './NetworkIssuesDialog'

const BuilderTranscribeNewPage = () => {
  useTitle('Clinical Notes AI - Transcribe')

  const [searchParams] = useSearchParams()

  const layoutIdentifier = searchParams.get('layout') || ''
  const dictation = searchParams.get('dictation') === 'true'
  const language = searchParams.get('language') || 'en'
  const startedAt = useMemo(() => new Date(), [])

  const navigate = useNavigate()

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const layout = layouts.find((l) => l.identifier === layoutIdentifier)

  const [finishLoading, setFinishLoading] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(Recorder.getIsRecording())
  const [isPaused, setIsPaused] = useState(false)
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null)
  const [device, setDevice] = useState<MediaDeviceInfo | null>(null)
  const [changeMicrophoneOpen, setChangeMicrophoneOpen] = useState(false)
  const [initialDialogOpen, setInitialDialogOpen] = useState(true)
  const [browserAudioEnabled, setBrowserAudioEnabled] = useState(false)
  const [starting, setStarting] = useState(false)
  const [socket, setSocket] = useState<TranscriptionSocket | null>(null)
  const [transcripts, setTranscripts] = useState<string[]>(retrieveTranscripts())
  const [sessionTimedOut, setSessionTimedOut] = useState(false)
  const [transactionId, setTransactionId] = useState<string | null>(null)
  const [requestId, setRequestId] = useState<string | null>(null)
  const [online, setOnline] = useState(true)
  const [networkOfflineOpen, setNetworkOfflineOpen] = useState(false)

  const handleChangeDevice = useCallback(
    async (options?: DeviceOptions) => {
      try {
        if (!socket) {
          log.warn('Socket does not exist')
          toast.error('Recorder has not been initialized, please refresh the page')
          return
        }

        const { deviceId, browserAudio } = options || {}

        setChangeMicrophoneOpen(false)

        const currentDeviceId = device?.deviceId
        if (currentDeviceId === deviceId && browserAudio === browserAudioEnabled) {
          return
        }

        const {
          analyser: newAnalyser,
          device: d,
          format,
        } = await Recorder.startRecording({
          sampleRate: 16000,
          channels: 1,
          onError: console.warn,
          onDebug: (msg) => {
            log.info('LIVE - debug - ', msg)
          },
          onDataAvailable: (data) => {
            if (!socket) {
              log.warn('Socket does not exist')
              return
            }
            socket.sendAudio(data)
          },
          deviceId,
          browserAudio,
        })

        setAnalyser(newAnalyser)
        setDevice(d)

        log.info(`LIVE - Device changed to ${d?.label}`)
        log.info(`LIVE - Sample rate changed to ${format.sampleRate}`)
        log.info(`LIVE - Channels changed to ${format.channels}`)
      } catch (err) {
        handleError(err)
      }
    },
    [device, browserAudioEnabled, socket]
  )

  const handleSendTranscript = useCallback(
    async (transcriptStr: string) => {
      try {
        setSendLoading(true)

        await checkToken()

        const now = new Date()

        log.info('LIVE - Sending transcript...')

        const duration = Math.ceil((now.getTime() - startedAt.getTime()) / 1000)
        const lang = isLanguageCode(language) ? language : 'en'

        const { result } = await transcribeRequest.sessions.transcribe.sendTranscript({
          token: '',
          startedAtString: startedAt.toLocaleDateString() + ' ' + startedAt.toLocaleTimeString(),
          endedAtString: now.toLocaleDateString() + ' ' + now.toLocaleTimeString(),
          transcript: transcriptStr,
          language: lang,
          duration,
          dictation,
          requestId: requestId || undefined,
        })

        log.info('LIVE - Finished - ', transcriptStr.length + ' characters')

        clearTranscripts()

        if (transactionId && !dictation) {
          transcriptionRequest.sessions
            .refineTranscript({
              token: '',
              sessionId: result._id,
              transactionId,
              duration,
              language: lang,
              transcript: transcriptStr,
            })
            .catch((err) => {
              log.error('Refined transcript request failed', err)
            })
        }

        const queryText = `?sessionId=${result._id}&dictation=${dictation}&language=${language}`
        navigate(`/builder/layout/${layoutIdentifier}${queryText}`)
      } catch (err) {
        handleError(err)
      } finally {
        setSendLoading(false)
      }
    },
    [navigate, layoutIdentifier, language, dictation, startedAt, transactionId, requestId]
  )

  const handleFinishTranscribe = useCallback(async () => {
    try {
      setIsRecording(false)
      setFinishLoading(true)

      log.info('LIVE - Finish starting...')

      try {
        // Play audio
        const audio = new Audio('/micend.wav')
        audio.play().catch(log.info)
      } catch (err) {
        log.info(err)
      }

      await checkToken()

      log.info('LIVE - Stopping...')
      await Recorder.stopRecording()

      log.info('LIVE - Finishing...')

      if (!socket) {
        log.warn('Socket does not exist on handle finish')
      }

      let finishedTranscript = ''
      try {
        const finishResponse = await socket?.finish()

        const { duration, retries, transcript: socketTranscript } = finishResponse || {}

        log.info('LIVE - Duration:', duration)
        log.info('LIVE - Retries:', retries)

        finishedTranscript = createTranscriptString(retrieveTranscripts()) || socketTranscript || ''
      } catch (err) {
        log.error('Failed to finish socket', err)
        finishedTranscript = createTranscriptString(retrieveTranscripts())
      }

      if (!finishedTranscript) {
        throw new Error('Transcript not found')
      }

      await handleSendTranscript(finishedTranscript)
    } catch (err) {
      log.error('Finish failed')
      handleError(err)
    } finally {
      setFinishLoading(false)
    }
  }, [socket, handleSendTranscript])

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const diff = now.getTime() - startedAt.getTime()
      if (diff > 1000 * 60 * 60 * 6) {
        handleFinishTranscribe()
      }
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [startedAt, handleFinishTranscribe])

  const handleStartSession = useCallback(
    async (options?: DeviceOptions) => {
      try {
        if (Recorder.getIsRecording()) {
          throw new Error('Recorder is already recording')
        }

        setStarting(true)
        setInitialDialogOpen(false)
        setIsRecording(true)

        log.info('LIVE - Starting...')

        const { browserAudio, deviceId } = options || {}

        await refreshToken()

        // Set online status & close network issues dialog
        setOnline(true)
        setNetworkOfflineOpen(false)

        const {
          analyser: newAnalyzer,
          device,
          format,
        } = await Recorder.startRecording({
          sampleRate: 16000,
          channels: 1,
          onError: console.warn,
          onDebug: (msg) => {
            log.info('LIVE - debug - ', msg)
          },
          deviceId,
          browserAudio,
        })

        const newSocket = createSocket({
          url: transcriptionServerUrl,
          onTranscriptAppend(transcripts) {
            if (
              typeof transcripts === 'object' &&
              Array.isArray(transcripts) &&
              transcripts.every((t) => typeof t === 'string')
            ) {
              // Append transcripts to the state
              storeTranscripts(transcripts)
              setTranscripts((prev) => [...prev, ...transcripts])
            } else {
              log.warn('Invalid transcripts:', transcripts)
            }
          },
          token: '',
          diarize: !dictation,
          skipSave: dictation,
          language,
          encoding: format.encoding,
          sampleRate: format.sampleRate,
          channels: format.channels,
          transactionId: transactionId || undefined,
          onRequestId: (id) => {
            setRequestId(id)
          },
          onNetworkStatus: (status) => {
            log.info('LIVE - Network status:', status)
            setOnline(status)

            if (!status) {
              toast.error('Network disconnected. Please check your connection.', {
                id: 'network-disconnected',
              })
            }
          },
          onTransactionId: (id) => {
            setTransactionId(id)
          },
          onClose: (reason) => {
            log.info('LIVE - Socket closed:', reason)
            Recorder.stopRecording()

            if (reason === 'finish') return

            if (reason === 'timeout') {
              setSessionTimedOut(true)
              return
            }
          },
          onError: (err) => {
            log.error('LIVE - ', err)
          },
          onDebug: (msg) => {
            log.info('LIVE - debug - ', msg)
          },
        })

        Recorder.setOnDataAvailable((data) => {
          newSocket.sendAudio(data)
        })

        setBrowserAudioEnabled(browserAudio || false)
        setAnalyser(newAnalyzer)
        setDevice(device)
        setSocket(newSocket)

        enableWakelock()

        // If starting the socket is unsuccessful, alert user of network issue
        try {
          await newSocket.start()
        } catch (err) {
          Recorder.stopRecording().catch(log.error)
          log.error(err)
          setNetworkOfflineOpen(true)
          return
        }

        // Set navigate away lock
        try {
          window.onbeforeunload = onBeforeUnloadBlocked
        } catch (err) {
          log.info(err)
        }

        log.info(`LIVE - Started, Device: ${device?.label || 'Default'}`)
      } catch (err) {
        handleError(err)
      } finally {
        setStarting(false)
      }
    },
    [dictation, language, transactionId]
  )

  const handleRefreshToken = useCallback(async () => {
    try {
      await checkToken()
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshToken()
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [handleRefreshToken])

  const handlePause = () => {
    setIsPaused(true)
  }

  const handleResume = () => {
    setIsPaused(false)
  }

  const handleNavBack = useCallback(async () => {
    try {
      setFinishLoading(true)

      await Recorder.stopRecording()

      await socket?.disconnect()

      await wait(100)

      refreshSessionsCache()

      navigate(`/builder`)
    } catch (err) {
      log.error(err)
    } finally {
      setFinishLoading(false)
    }
  }, [navigate, socket])

  // Close the socket once the page is unmounted
  useEffect(() => {
    if (!socket) return

    return () => {
      console.log('unmount stop socket')
      socket?.disconnect()
    }
  }, [socket])

  // Stop the recorder once the page is unmounted
  useEffect(() => {
    return () => {
      console.log('unmount stop recording')
      // Remove navigate away lock
      try {
        window.onbeforeunload = onBeforeUnload
      } catch (err) {
        log.info(err)
      }

      // Remove wake lock
      disableWakelock()

      Recorder.stopRecording().catch((err) => {
        log.error(err)
      })
    }
  }, [])

  const html = useMemo(() => {
    return layout
      ? dictation
        ? getContainerHTML(markdownToHtml(layout.dictationDescription))
        : getContainerHTML(markdownToHtml(layout.sessionDescription || layout.dictationDescription))
      : ''
  }, [layout, dictation])

  const handleResumeSession = useCallback(() => {
    setSessionTimedOut(false)
    handleStartSession()
  }, [handleStartSession])

  if (sessionTimedOut) {
    return (
      <ResumeSessionDialog open={true} onClose={handleNavBack} onResume={handleResumeSession} />
    )
  }

  if (transcripts.length !== 0 && !isRecording && !finishLoading) {
    return (
      <RecoverSessionDialog
        open={true}
        loading={sendLoading}
        transcript={createTranscriptString(transcripts)}
        onClose={handleNavBack}
        onDiscard={() => {
          setTranscripts([])
          clearTranscripts()
        }}
        onConfirm={() => handleSendTranscript(createTranscriptString(transcripts))}
      />
    )
  }

  if (networkOfflineOpen) {
    return (
      <NetworkIssuesDialog
        open
        loading={sendLoading}
        onClose={handleNavBack}
        onRetry={handleStartSession}
      />
    )
  }

  if (initialDialogOpen) {
    return (
      <SelectAudioDeviceDialog
        open={true}
        dictation={dictation}
        onClose={handleNavBack}
        onDeviceSelected={handleStartSession}
        disabled={starting}
      />
    )
  }

  return (
    <>
      <CheckAudioSupportDialog />
      {finishLoading && (
        <OverlayLoader>
          <Typography variant="body1" fontWeight={500} textAlign="center" sx={{ pb: 2 }}>
            Loading...
            <br />
            Please do not exit this page
          </Typography>
        </OverlayLoader>
      )}
      <NewRecorderOverlay
        recording={isRecording}
        paused={isPaused}
        loading={finishLoading || !online}
        onClickPause={handlePause}
        onClickResume={handleResume}
        onClickFinish={handleFinishTranscribe}
        onClickBack={handleNavBack}
        onClickChangeMicrophone={() => setChangeMicrophoneOpen(true)}
        analyser={analyser}
        transcript={createTranscriptString(transcripts)}
        finishLabel={dictation ? 'Finish Dictation' : 'Finish Session'}
        pauseLabel={dictation ? 'Pause Dictation' : 'Pause Session'}
        resumeLabel={dictation ? 'Resume Dictation' : 'Resume Session'}
        device={device}
        overlayText={
          html ? (
            <Box
              sx={{
                fontSize: 16,
                textAlign: 'left',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: removeMaliciousHTML(html),
                }}
              />
            </Box>
          ) : undefined
        }
      />
      <ChangeMicrophoneDialog
        open={changeMicrophoneOpen}
        onClose={() => setChangeMicrophoneOpen(false)}
        onSelectDevice={handleChangeDevice}
        browserAudio={browserAudioEnabled}
      />
    </>
  )
}

export default BuilderTranscribeNewPage
