import { InputField, OutputField, TableInputField } from '@counsel-project/counsel-generation-api'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useCallback } from 'react'
import MultiChoiceTemplateField from './MultiChoiceTemplateField'
import SingleChoiceTemplateField from './SingleChoiceTemplateField'
import StringTemplateField from './StringTemplateField'
import TableTemplateField from './TableTemplateField'

type TemplateFieldProps = {
  inputField: InputField
  value?: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const TemplateField = ({ inputField, value, onChange }: TemplateFieldProps) => {
  const { type } = inputField

  if (type === 'single_choice') {
    // Select
    return (
      <SingleChoiceTemplateField inputField={inputField} value={value || []} onChange={onChange} />
    )
  } else if (type === 'multiple_choice') {
    // MultiSelect
    return (
      <MultiChoiceTemplateField inputField={inputField} value={value || []} onChange={onChange} />
    )
  } else if (type === 'string') {
    // Text
    return <StringTemplateField inputField={inputField} value={value || []} onChange={onChange} />
  } else if (type === 'table') {
    // Table
    return (
      <TableTemplateField
        inputField={inputField as TableInputField}
        value={value || []}
        onChange={onChange}
      />
    )
  }

  return (
    <Grid item>
      {/* <Typography variant="body1" color="error">
        Invalid field type
      </Typography> */}
    </Grid>
  )
}

type TemplateFieldsProps = {
  inputFields: InputField[]
  outputFields?: OutputField[]
  onChange: (outputFields: OutputField[]) => void
}

const TemplateFields = ({ inputFields, outputFields, onChange }: TemplateFieldsProps) => {
  const handleChange = useCallback(
    (inputField: InputField) =>
      ({ id, value }: { id: string; value: string[] }) => {
        const newOutputFields = outputFields?.map((outputField) => {
          if (outputField.id === id) return { ...outputField, value }
          return outputField
        }) || [
          {
            id,
            name: inputField.name,
            type: inputField.type,
            value,
          },
        ]
        onChange(newOutputFields)
      },
    [outputFields, onChange]
  )

  return (
    <Box>
      <Grid container spacing={2}>
        {inputFields.map((inputField) => {
          const outputField: OutputField = outputFields?.find(
            (outputField) => outputField.id === inputField.id
          ) || {
            id: inputField.id,
            name: inputField.name,
            type: inputField.type,
            value: [],
          }
          return (
            <TemplateField
              key={inputField.id}
              inputField={inputField}
              value={outputField.value}
              onChange={handleChange(inputField)}
            />
          )
        })}
      </Grid>
    </Box>
  )
}

export default TemplateFields
