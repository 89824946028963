import {
  RIntegrationTemplate,
  IntegrationTemplateSortableFields,
} from '@counsel-project/counsel-ehr-api'
import IntegrationTemplateRow from './IntegrationTemplateRow'
import TableBase from './TableBase'
import useMediaQuery from '@mui/material/useMediaQuery'

export type IntegrationTemplateTableProps = {
  searchDisabled?: boolean
  rows: RIntegrationTemplate[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort: IntegrationTemplateSortableFields
  direction: 'asc' | 'desc'
  onChangeSort?: (sort: IntegrationTemplateSortableFields, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RIntegrationTemplate) => void
  onToggleEnabled?: (row: RIntegrationTemplate) => void
  onSubmitSearch?: (search: string) => void
}

const IntegrationTemplateTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onToggleEnabled,
  onSubmitSearch,
}: IntegrationTemplateTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'name', label: 'Name', sortable: true, align: 'left' },
        {
          field: 'enabled',
          label: 'Enabled',
          sortable: true,
          align: 'right',
          hidden: isMobile,
        },
      ]}
      rows={rows}
      renderRow={(row: RIntegrationTemplate) => (
        <IntegrationTemplateRow
          key={row._id}
          isMobile={isMobile}
          data={row}
          onClick={onClickRow}
          onToggleEnabled={onToggleEnabled}
        />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      sort={sort}
      direction={direction}
      onChangeSort={onChangeSort as any}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default IntegrationTemplateTable
