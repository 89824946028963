import { RLayout } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { capitalize } from '@counsel-project/client-utils'
import { StyledChip } from '../../components/NewChip'

type LayoutOptionProps = {
  selected?: boolean
  layout: RLayout
  onClick: () => void
}

const LayoutOption = ({ selected, layout, onClick }: LayoutOptionProps) => {
  const imgSrc = layout.type === 'document' ? '/icons/book-2.svg' : '/icons/notes.svg'

  return (
    <Paper
      id={'layout-option-' + layout.identifier}
      elevation={4}
      sx={(theme) => ({
        height: '100%',
        cursor: 'pointer',
        ...(selected
          ? {
              outline: `2px solid ${theme.palette.primary.main}`,
              boxShadow: `0px 0px 10px 0px ${theme.palette.primary.main}`,
            }
          : {}),
        '&:hover': {
          boxShadow: theme.shadows[8],
        },
      })}
      onClick={onClick}
    >
      <Grid
        container
        padding={2}
        alignItems="stretch"
        direction="column"
        justifyContent="space-between"
        sx={{ height: '100%' }}
      >
        <Grid item>
          <Typography variant="body1" component="h3" fontSize={18} gutterBottom>
            {layout.name}
          </Typography>
          <Typography variant="body1" gutterBottom color="text.secondary">
            {layout.description}
          </Typography>
        </Grid>
        <Grid item container spacing={1} alignItems="center">
          <Grid item container xs={12} alignItems="center" direction="row">
            <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={imgSrc} alt="icon" width={24} height={24} style={{ userSelect: 'none' }} />
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ ml: 1 }}>
                {capitalize(layout.type)}
              </Typography>
            </Grid>
            {layout.config.multiplePeople && (
              <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <StyledChip size="small" sx={{ ml: 1 }} label="Group" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default LayoutOption
