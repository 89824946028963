import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded'
import DownloadIcon from '@mui/icons-material/DownloadRounded'
import FeedRoundedIcon from '@mui/icons-material/FeedRounded'
import PaymentIcon from '@mui/icons-material/PaymentRounded'
import PersonIcon from '@mui/icons-material/PersonRounded'
import AdminIcon from '@mui/icons-material/SettingsRounded'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import Cookies from 'js-cookie'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useActiveLicense from '../util/auth/useActiveLicense'
import useUser from '../util/auth/useUser'
import StatsIcon from '@mui/icons-material/BarChartRounded'

export type AccountButtonProps = {
  disabled?: boolean
}

const AccountButton = ({ disabled }: AccountButtonProps) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [user] = useUser()

  const [activeLicense, , populateActiveLicense] = useActiveLicense()

  const username = user?.firstName ? `${user?.firstName} ${user?.lastName}`.trim() : user?.email
  const alt = user?.firstName?.[0] ?? user?.email?.[0] ?? 'A'
  const picture = user?.picture
  const email = user?.email
  const admin = user?.admin

  const [accountOpen, setAccountOpen] = useState(false)
  const accountButtonEl = useRef<HTMLAnchorElement>(null)

  const handleClickAccountButton = (e: React.MouseEvent) => {
    e.preventDefault()
    if (disabled) return
    setAccountOpen(true)
  }

  const handleCloseAccountMenu = () => {
    setAccountOpen(false)
  }

  const createAccountNavHandler = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    handleCloseAccountMenu()
    navigate(path)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateActiveLicense().catch(console.error)
    }, 400)
    return () => {
      clearTimeout(timeout)
    }
  }, [populateActiveLicense])

  const handleOpenConsentDocument = (e: React.MouseEvent) => {
    e.preventDefault()
    handleCloseAccountMenu()
    window.open('/consent-document.pdf', '_blank')
  }

  const handleOpenBAA = (e: React.MouseEvent) => {
    e.preventDefault()
    handleCloseAccountMenu()
    Cookies.remove('dl-baa')
    navigate('/onboarding')
  }

  return (
    <>
      <Box
        onClick={handleClickAccountButton}
        ref={accountButtonEl}
        id="account-button"
        component="a"
        href="#open-profile-menu"
        sx={{
          userSelect: 'none',
          p: 1,
          backgroundColor: alpha(theme.palette.primary.main, 0.075),
          borderRadius: 200,
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          justifyContent: 'center',
          textDecoration: 'none',
          ':hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
          },
        }}
      >
        <Avatar
          alt={alt}
          src={picture ?? undefined}
          sx={(theme) => ({ bgcolor: theme.palette.primary.main, width: 26, height: 26 })}
        />
      </Box>
      <Menu
        aria-expanded={accountOpen ? 'true' : undefined}
        id="account-menu"
        anchorEl={accountButtonEl.current}
        open={accountOpen}
        onClose={handleCloseAccountMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[24],
          },
        }}
      >
        <Typography sx={{ px: 2, pt: 1 }} variant="body2" fontWeight={500}>
          {username}
        </Typography>
        {username !== email && (
          <Typography sx={{ px: 2 }} variant="subtitle1" color="text.secondary" fontWeight={500}>
            {email}
          </Typography>
        )}
        {!activeLicense ? (
          <Chip
            id="no-license-chip"
            label="No License Active"
            color="error"
            sx={{ mx: 2, mb: 1, mt: 1, fontWeight: 600, fontSize: '0.85rem' }}
          />
        ) : (
          <Chip
            id="active-license-chip"
            label={`${activeLicense.type} Membership`}
            color="primary"
            sx={{ mx: 2, mb: 1, mt: 1, fontWeight: 600, fontSize: '0.85rem' }}
          />
        )}
        <MenuItem
          id="account-nav-profile"
          color="primary"
          LinkComponent="a"
          href="/profile"
          onClick={createAccountNavHandler('/profile')}
        >
          <PersonIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">My Profile</Typography>
        </MenuItem>
        <MenuItem
          id="account-nav-subscriptions"
          color="primary"
          LinkComponent="a"
          href="/subscriptions"
          onClick={createAccountNavHandler('/subscriptions')}
        >
          <PaymentIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">Billing</Typography>
        </MenuItem>
        <MenuItem
          id="account-nav-organization"
          color="primary"
          LinkComponent="a"
          href="/organization"
          onClick={createAccountNavHandler('/organization')}
        >
          <CorporateFareRoundedIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">My Organization</Typography>
        </MenuItem>
        <MenuItem
          id="account-nav-stats"
          color="primary"
          LinkComponent="a"
          href="/stats"
          onClick={createAccountNavHandler('/stats')}
        >
          <StatsIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">Metrics</Typography>
        </MenuItem>
        <MenuItem
          id="account-nav-changelog"
          color="primary"
          LinkComponent="a"
          href="/changelog"
          onClick={createAccountNavHandler('/changelog')}
        >
          <FeedRoundedIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">Changelog</Typography>
        </MenuItem>
        <MenuItem
          id="account-nav-download-consent"
          color="primary"
          LinkComponent="a"
          href="/changelog"
          onClick={handleOpenConsentDocument}
        >
          <DownloadIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">Consent Document</Typography>
        </MenuItem>
        <MenuItem
          id="account-nav-download-consent"
          color="primary"
          LinkComponent="a"
          href="/baa"
          onClick={handleOpenBAA}
        >
          <DownloadIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">BAA</Typography>
        </MenuItem>
        {admin && (
          <MenuItem
            id="account-nav-admin-logs"
            color="primary"
            LinkComponent="a"
            href="/admin"
            onClick={createAccountNavHandler('/admin')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Logs</Typography>
          </MenuItem>
        )}
        {admin && (
          <MenuItem
            id="account-nav-admin-note-logs"
            color="primary"
            LinkComponent="a"
            href="/admin/notes"
            onClick={createAccountNavHandler('/admin/notes')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Note Logs</Typography>
          </MenuItem>
        )}
        {admin && (
          <MenuItem
            id="account-nav-admin-users"
            color="primary"
            LinkComponent="a"
            href="/licenses"
            onClick={createAccountNavHandler('/admin/licenses')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Licensing</Typography>
          </MenuItem>
        )}
        {admin && (
          <MenuItem
            id="account-nav-admin-organizations"
            color="primary"
            LinkComponent="a"
            href="/organizations"
            onClick={createAccountNavHandler('/admin/organizations')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Organizations</Typography>
          </MenuItem>
        )}
        {admin && (
          <MenuItem
            id="account-nav-admin-sessions"
            color="primary"
            LinkComponent="a"
            href="/sessions"
            onClick={createAccountNavHandler('/admin/sessions')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Sessions</Typography>
          </MenuItem>
        )}
        {admin && (
          <MenuItem
            id="account-nav-admin-layouts"
            color="primary"
            LinkComponent="a"
            href="/layouts/admin"
            onClick={createAccountNavHandler('/layouts/admin')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Templates</Typography>
          </MenuItem>
        )}
        {admin && (
          <MenuItem
            id="account-nav-admin-transcripts"
            color="primary"
            LinkComponent="a"
            href="/admin/transcripts"
            onClick={createAccountNavHandler('/admin/transcripts')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Deidentifier</Typography>
          </MenuItem>
        )}
        {admin && (
          <MenuItem
            id="account-nav-admin-users"
            color="primary"
            LinkComponent="a"
            href="/admin/users"
            onClick={createAccountNavHandler('/admin/users')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin Users</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default AccountButton
