import { RTranscript } from '@counsel-project/counsel-deidentifier-api/dist/common/database/Transcript'
import BackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

type TranscriptDialogProps = {
  open: boolean
  onClose: () => void
  transcript: RTranscript
}

const TranscriptDialog = ({ open, onClose, transcript }: TranscriptDialogProps) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <BackIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 4 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="p">
                  AI Generated Material
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {transcript.transcript?.split('\n')?.map((line, i) => (
                    <span key={i} className="data-hj-suppress">
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Typography variant="subtitle2" component="p" sx={{ mt: 2 }}>
            {transcript.tags.map((tag, i) => (
              <span key={i}>
                {tag}
                {i < transcript.tags.length - 1 && ', '}
              </span>
            ))}
          </Typography>
          <Typography variant="body1" component="p">
            {new Date(transcript.createdAt).toString()}
          </Typography>
          <Typography variant="body1" component="p">
            Patient: {transcript.patient}
          </Typography>
          <Typography variant="body1" component="p">
            Identity: {transcript.identity}
          </Typography>
          <Typography variant="body1" component="p">
            inputTokens: {transcript.inputTokens}
          </Typography>
          <Typography variant="body1" component="p">
            outputTokens: {transcript.outputTokens}
          </Typography>
          <Typography variant="body1" component="p">
            Duration: {transcript.duration}s
          </Typography>
          <Typography variant="body1" component="p">
            Patient Iteration: {transcript.patientIncrement}
          </Typography>
          <Typography variant="body1" component="p">
            Deidentifier Version: {transcript.deidentifierVersion}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TranscriptDialog
