import { InputField } from '@counsel-project/counsel-generation-api'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

type StringTemplateFieldProps = {
  inputField: InputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const StringTemplateField = ({ inputField, value, onChange }: StringTemplateFieldProps) => {
  const { id, name, type, instructions } = inputField

  const [answer, setAnswer] = useState<string>('')

  useEffect(() => {
    if (value && type === 'string') {
      setAnswer(value[0])
    }
  }, [value, type])

  return (
    <Grid item xs={12} id={`field-${id}`}>
      <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
        {name}
      </Typography>
      <TextField
        placeholder={instructions?.[0] || name}
        value={answer}
        fullWidth
        type="text"
        autoComplete="off"
        multiline
        onChange={(e) => {
          setAnswer(e.target.value)
          onChange?.({ id, value: [e.target.value] })
        }}
      />
    </Grid>
  )
}

export default StringTemplateField
