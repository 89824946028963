import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import { capitalize } from '@counsel-project/client-utils'
import { StyledChip } from '../../components/NewChip'
import React from 'react'

type CustomLayoutCardProps = {
  isOwner: boolean
  layout: RLayout
  onClick?: () => void
  href: string
}

const CustomLayoutCard = ({ isOwner, layout, onClick, href }: CustomLayoutCardProps) => {
  const imgSrc = layout.type === 'document' ? '/icons/book-2.svg' : '/icons/notes.svg'

  const capitalType = capitalize(layout.type)

  const shortenedDescription =
    layout.description.length > 70 ? layout.description.slice(0, 67) + '...' : layout.description

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (onClick) onClick()
  }

  return (
    <Paper
      component="a"
      href={href}
      elevation={2}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 6,
        },
        height: 240,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textDecoration: 'none',
      }}
      onClick={handleClick}
    >
      <Box px={2} pt={2} sx={{ flexGrow: 1 }}>
        <Typography variant="h6" textOverflow="ellipsis" overflow="hidden" sx={{ maxHeight: 64 }}>
          {layout.name}
        </Typography>
        <Typography variant="body2" fontWeight={500}>
          {shortenedDescription}
        </Typography>
      </Box>
      <Box p={2}>
        <Typography variant="body1" color="text.primary" fontWeight={500}>
          {layout.documentType}
        </Typography>
        {layout.directoryName && (
          <Typography variant="body2" color="text.secondary">
            Shared with{' '}
            <Typography variant="body2" component="span" fontWeight={500}>
              {layout.directoryName}
            </Typography>
          </Typography>
        )}
        {!!layout.userId && !isOwner ? (
          <Typography
            variant="body2"
            color="text.secondary"
            textOverflow="ellipsis"
            overflow="hidden"
            noWrap
          >
            Created by{' '}
            <Typography variant="body2" component="span" fontWeight={500}>
              {layout.email}
            </Typography>
          </Typography>
        ) : (
          <Typography variant="body2" color="primary" fontWeight={500}>
            Created by you
          </Typography>
        )}
        {!layout.userId && (
          <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <Grid item container xs={12} alignItems="center" direction="row">
              <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  src={imgSrc}
                  alt="icon"
                  width={24}
                  height={24}
                  style={{ userSelect: 'none' }}
                />
              </Grid>
              <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ ml: 1 }} fontWeight={500}>
                  {capitalType}
                </Typography>
              </Grid>
              {layout.config.multiplePeople && (
                <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <StyledChip size="small" sx={{ ml: 1 }} label="Group" />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  )
}

export default CustomLayoutCard
