import MailIcon from '@mui/icons-material/MailRounded'
import StarIcon from '@mui/icons-material/StarRounded'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from '../../components/ClosableDialog'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import { verifyUpdateEmail } from '../../util/auth/verify'

type ChangeEmailDialogProps = {
  open: boolean
  onClose: () => void
}

const ChangeEmailDialog = ({ open, onClose }: ChangeEmailDialogProps) => {
  const [user] = useUser()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userId, setUserId] = useState('')
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)

  const handleUpdateEmail = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const result = await authRequest.user.account.updateEmail({
        token: '',
        email,
        password,
        refreshToken: Cookies.get('refreshToken') || '',
      })

      setUserId(result.userId)
      setCode('')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [email, password])

  const handleVerifyEmail = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await verifyUpdateEmail({
        userId,
        code,
      })

      setUserId('')
      setCode('')
      onClose()
      toast.success('Email updated successfully!')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [userId, code, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} id="change-email-dialog" titleText="Change Email">
      <Collapse in={!userId}>
        <DialogContent>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Type your new email address and password below to change your email.
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            New Email Address
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            placeholder="Enter your new email address"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
          />
          {user?.password ? (
            <>
              <Typography variant="body1" fontWeight={500} sx={{ mt: 2 }}>
                Verify Your Password
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                placeholder="Enter your password"
                type="password"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          ) : (
            <>
              <Typography variant="body1" fontWeight={500} sx={{ mt: 2 }}>
                Create New Password
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                placeholder="Enter your new password"
                type="password"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          )}
        </DialogContent>
      </Collapse>
      <Collapse in={!!userId}>
        <DialogContent>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Enter the verification code sent to your new email address.
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            Verification Code
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            placeholder="Enter your verification code"
            type="text"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
      </Collapse>
      <DialogActions>
        {!userId ? (
          <Button
            disabled={!email || !password || loading}
            color="primary"
            startIcon={<MailIcon />}
            onClick={handleUpdateEmail}
          >
            Send Verification Email
          </Button>
        ) : (
          <Button
            disabled={!code || loading}
            color="primary"
            startIcon={<StarIcon />}
            onClick={handleVerifyEmail}
          >
            Verify Email
          </Button>
        )}
      </DialogActions>
    </ClosableDialog>
  )
}

export default ChangeEmailDialog
